import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Check, X } from 'lucide-react';
import Modal from '../../../../components/modal/Modal';

// Import the markdown parser
import MarkdownIt from 'markdown-it';

// Import the editor styles
import 'react-markdown-editor-lite/lib/index.css';
import { TermsAndConditions } from '../../../../Types/TermsAndConditions';

// Document Types enum
export enum DocumentTypes {
  TERMS_OF_SERVICE = 'TermsOfService',
  PRIVACY_POLICY = 'PrivacyPolicy',
  COMPANY_POLICY = 'CompanyPolicy',
  DRIVER_AGREEMENT = 'DriverAgreement',
}

// Initialize markdown parser
const mdParser = new MarkdownIt();

// Lazy load the Markdown editor to avoid issues with browser-specific APIs
const MarkdownEditor = lazy(() => import('react-markdown-editor-lite'));


interface TermsAndConditionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: TermsAndConditions) => void;
  initialData?: TermsAndConditions;
}

interface EditorChangeObject {
  text: string;
  html: string;
}

const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialData
}) => {
  const [formData, setFormData] = useState<TermsAndConditions>({
    title: '',
    content: '',
    effectiveDate: new Date(),
    isCurrentVersion: true,
    documentType: DocumentTypes.TERMS_OF_SERVICE
  });
  
  // State to check if we're in the browser environment
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    // Set browser state to true when component mounts
    setIsBrowser(true);
    
    if (initialData) {
      setFormData({
        ...initialData,
        effectiveDate: new Date(initialData.effectiveDate)
      });
    } else {
      // Reset form when no initial data
      setFormData({
        title: '',
        content: '',
        effectiveDate: new Date(),
        isCurrentVersion: true,
        documentType: DocumentTypes.TERMS_OF_SERVICE
      });
    }
  }, [initialData, isOpen]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      effectiveDate: new Date(e.target.value)
    }));
  };

  const handleEditorChange = ({ text }: EditorChangeObject) => {
    setFormData(prev => ({
      ...prev,
      content: text
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  // Format date for input field
  const formatDateForInput = (date: Date): string => {
    return date.toISOString().split('T')[0];
  };

  if (!isOpen) return null;

  return (
    <Modal 
      isVisible={isOpen} 
      onClosed={onClose} 
    //   size="xl"
    >
      <div className="p-2">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">
            {initialData ? 'Edit Document' : 'Add New Document'}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex flex-col gap-2">
            <label htmlFor="documentType" className="block text-sm font-medium text-gray-700">
              Document Type
            </label>
            <select
              id="documentType"
              name="documentType"
              value={formData.documentType}
              onChange={handleInputChange}
              className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              required
            >
              <option value={DocumentTypes.TERMS_OF_SERVICE}>Terms of Service</option>
              <option value={DocumentTypes.PRIVACY_POLICY}>Privacy Policy</option>
              <option value={DocumentTypes.COMPANY_POLICY}>Company Policy</option>
              <option value={DocumentTypes.DRIVER_AGREEMENT}>Driver Agreement</option>
            </select>
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              id="title"
              name="title"
              type="text"
              value={formData.title}
              onChange={handleInputChange}
              className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              required
              placeholder="e.g., Driver Agreement 2025"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="effectiveDate" className="block text-sm font-medium text-gray-700">
              Effective Date
            </label>
            <input
              id="effectiveDate"
              name="effectiveDate"
              type="date"
              value={formatDateForInput(formData.effectiveDate)}
              onChange={handleDateChange}
              className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
              required
            />
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="content" className="block text-sm font-medium text-gray-700">
              Content (Markdown)
            </label>
            <div className="border border-gray-400 rounded-md overflow-hidden">
              {isBrowser ? (
                <Suspense fallback={<div className="p-4 text-center">Loading editor...</div>}>
                  <MarkdownEditor
                    value={formData.content}
                    style={{ height: '400px' }}
                    onChange={handleEditorChange}
                    renderHTML={(text: string) => mdParser.render(text)}
                  />
                </Suspense>
              ) : (
                <div className="p-4 text-center">Editor loading...</div>
              )}
            </div>
            <p className="text-xs text-gray-500 mt-1">
              Use Markdown to format your content. You can add headers, lists, bold text, links, and more.
            </p>
          </div>

          {!initialData && (
            <div className="flex items-center">
              <input
                id="isCurrentVersion"
                name="isCurrentVersion"
                type="checkbox"
                checked={formData.isCurrentVersion}
                onChange={() => 
                  setFormData(prev => ({ ...prev, isCurrentVersion: !prev.isCurrentVersion }))
                }
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
              <label htmlFor="isCurrentVersion" className="ml-2 block text-sm text-gray-700">
                Set as current version (will replace any existing current version of this document type)
              </label>
            </div>
          )}

          <div className="flex justify-end space-x-3 mt-8">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-darkBlue-100 text-white-100 rounded-md hover:bg-blue-700 flex items-center"
            >
              <Check size={20} className="mr-2" />
              {initialData ? 'Update' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default TermsAndConditionsModal;