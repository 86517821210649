import api from "../http/api/api_client";
import { TermsAndConditions } from "../Types/TermsAndConditions";

// Document types enum (for reference)
export const DocumentTypes = {
    TERMS_OF_SERVICE: 'TermsOfService',
    PRIVACY_POLICY: 'PrivacyPolicy',
    COMPANY_POLICY: 'CompanyPolicy',
    DRIVER_AGREEMENT: 'DriverAgreement',
};

export const TermsAndConditionsService = {
    // Get all terms and conditions
    async getAll() {
        try {
            const response = await api.get('/api/TermsAndConditions');
            return {
                isSuccessful: true,
                data: response.data.data,
            };
        } catch (error: any) {
            console.error('Error fetching all terms and conditions:', error);
            return {
                isSuccessful: false,
                error: error.response?.data || error.message,
            };
        }
    },

    // Get terms and conditions by document type
    async getByDocumentType(documentType: any) {
        try {
            const response = await api.get(`/api/TermsAndConditions/GetTermsAndConditionsByDocumentType`, {
                "params": {
                    "documentType": documentType
                }
            });
            return {
                isSuccessful: true,
                data: response.data.data,
            };
        } catch (error: any) {
            console.error(`Error fetching terms and conditions for document type ${documentType}:`, error);
            return {
                isSuccessful: false,
                error: error.response?.data || error.message,
            };
        }
    },

    // Get terms and conditions by ID
    async getById(id: string) {
        try {
            const response = await api.get(`/api/TermsAndConditions/GetTermsAndConditionsById`, {
                "params": {
                    "id": id
                }
            });
            return {
                isSuccessful: true,
                data: response.data.data,
            };
        } catch (error: any) {
            console.error(`Error fetching terms and conditions with ID ${id}:`, error);
            return {
                isSuccessful: false,
                error: error.response?.data || error.message,
            };
        }
    },

    // Get current terms and conditions for a specific document type
    async getCurrentByType(documentType: any) {
        try {
            const response = await api.get(`/api/TermsAndConditions/GetCurrentTermsAndConditions`, {
                "params": {
                    "documentType": documentType
                }
            });
            return {
                isSuccessful: true,
                data: response.data.data,
            };
        } catch (error: any) {
            console.error(`Error fetching current terms and conditions for document type ${documentType}:`, error);
            return {
                isSuccessful: false,
                error: error.response?.data || error.message,
            };
        }
    },

    // Get all current terms and conditions
    async getAllCurrent() {
        try {
            const response = await api.get('/api/TermsAndConditions/GetAllCurrentTermsAndConditions');
            return {
                isSuccessful: true,
                data: response.data.data,
            };
        } catch (error: any) {
            console.error('Error fetching all current terms and conditions:', error);
            return {
                isSuccessful: false,
                error: error.response?.data || error.message,
            };
        }
    },

    // Create new terms and conditions
    async create(termsAndConditions: TermsAndConditions) {
        try {
            const response = await api.post('/api/TermsAndConditions', termsAndConditions);
            return {
                isSuccessful: true,
                data: response.data,
            };
        } catch (error: any) {
            console.error('Error creating terms and conditions:', error);
            return {
                isSuccessful: false,
                error: error.response?.data || error.message,
            };
        }
    },

    // Update terms and conditions
    async update(termsAndConditions: TermsAndConditions) {
        try {
            const response = await api.put(
                `/api/TermsAndConditions/UpdateTermsAndConditions/${termsAndConditions.id}`,
                termsAndConditions
            );
            return {
                isSuccessful: true,
                data: response.data,
            };
        } catch (error: any) {
            console.error('Error updating terms and conditions:', error);
            return {
                isSuccessful: false,
                error: error.response?.data || error.message,
            };
        }
    },

    // Delete terms and conditions
    async delete(id: string) {
        try {
            await api.delete(`/api/TermsAndConditions/DeleteTermsAndConditions/${id}`);
            return {
                isSuccessful: true,
            };
        } catch (error: any) {
            console.error(`Error deleting terms and conditions with ID ${id}:`, error);
            return {
                isSuccessful: false,
                error: error.response?.data || error.message,
            };
        }
    },

    // Make terms and conditions the current version
    async makeCurrent(id: any) {
        try {
            const response = await api.put(`/api/TermsAndConditions/MakeTermsAndConditionsCurrent/${id}`);
            return {
                isSuccessful: true,
                data: response.data,
            };
        } catch (error: any) {
            console.error(`Error making terms and conditions with ID ${id} current:`, error);
            return {
                isSuccessful: false,
                error: error.response?.data || error.message,
            };
        }
    }
};