import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { FiMaximize, FiMinimize, FiDownload } from 'react-icons/fi';
import { MdOutlineZoomIn, MdOutlineZoomOut } from 'react-icons/md';

interface TruckImagePreviewModalProps {
  imageUrl: string;
  altText?: string;
  isOpen: boolean;
  onClose: () => void;
}

const TruckImagePreviewModal: React.FC<TruckImagePreviewModalProps> = ({
  imageUrl,
  altText = 'Image preview',
  isOpen,
  onClose,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Handle keyboard shortcuts
  useEffect(() => {
    if (!isOpen) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      // ESC key to close
      if (e.key === 'Escape') {
        onClose();
      }
      // Plus key to zoom in
      else if (e.key === '+' || e.key === '=') {
        handleZoomIn();
      }
      // Minus key to zoom out
      else if (e.key === '-' || e.key === '_') {
        handleZoomOut();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  // Handle image loading
  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setError('Failed to load image. The image may be corrupted or no longer available.');
  };

  // Toggle fullscreen
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable fullscreen: ${err.message}`);
      });
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  // Handle zoom
  const handleZoomIn = () => {
    if (zoomLevel < 300) {
      setZoomLevel(prev => prev + 25);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 50) {
      setZoomLevel(prev => prev - 25);
    }
  };

  // Handle download
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = altText.replace(/\s+/g, '_').toLowerCase() + '.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // If not open, don't render anything
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-80 flex flex-col items-center justify-center z-50 backdrop-blur-sm"
      onClick={onClose}
    >
      {/* Toolbar */}
      <div 
        className="absolute top-0 left-0 right-0 p-4 flex justify-between z-10 text-white bg-gradient-to-b from-black/60 to-transparent"
        onClick={e => e.stopPropagation()} // Prevent closing when clicking toolbar
      >
        <div className="text-lg font-medium truncate max-w-md">
          {altText}
        </div>
        <div className="flex items-center gap-4">
          <button 
            onClick={handleZoomOut}
            className="p-2 rounded-full hover:bg-white/20 transition-colors flex items-center justify-center"
            disabled={zoomLevel <= 50}
          >
            <MdOutlineZoomOut size={20} />
          </button>
          <div className="text-sm bg-white/20 px-3 py-1 rounded-full">
            {zoomLevel}%
          </div>
          <button 
            onClick={handleZoomIn}
            className="p-2 rounded-full hover:bg-white/20 transition-colors flex items-center justify-center"
            disabled={zoomLevel >= 300}
          >
            <MdOutlineZoomIn size={20} />
          </button>
          <button 
            onClick={handleDownload}
            className="p-2 rounded-full hover:bg-white/20 transition-colors flex items-center justify-center"
          >
            <FiDownload size={20} />
          </button>
          <button 
            onClick={toggleFullscreen}
            className="p-2 rounded-full hover:bg-white/20 transition-colors flex items-center justify-center"
          >
            {isFullscreen ? <FiMinimize size={20} /> : <FiMaximize size={20} />}
          </button>
          <button 
            onClick={onClose}
            className="p-2 rounded-full hover:bg-white/20 transition-colors flex items-center justify-center"
          >
            <IoMdClose size={24} />
          </button>
        </div>
      </div>

      {/* Image Container */}
      <div 
        className="relative overflow-auto max-w-full max-h-full p-4 touch-pan-y"
        onClick={e => e.stopPropagation()} // Prevent closing when clicking image
      >
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-12 h-12 border-4 border-white/30 border-t-white rounded-full animate-spin"></div>
          </div>
        )}
        
        {error && (
          <div className="bg-red-800/50 text-white p-6 rounded-lg text-center">
            <div className="text-5xl mb-4">⚠️</div>
            <p>{error}</p>
          </div>
        )}
        
        <img 
          src={imageUrl} 
          alt={altText}
          className="max-w-none transition-transform duration-200 ease-out"
          style={{ 
            transform: `scale(${zoomLevel / 100})`,
            opacity: isLoading ? 0 : 1,
            display: error ? 'none' : 'block'
          }}
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
      </div>

      {/* Instructions */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white/60 text-sm px-4 py-2 bg-black/40 rounded-full">
        Use +/- keys to zoom or ESC to close
      </div>
    </div>
  );
};

export default TruckImagePreviewModal;