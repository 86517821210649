import React, { useState } from 'react';
import { MdEmail, MdPhone, MdLocationOn, MdDateRange } from 'react-icons/md';
import { FaIdCard, FaUserCircle } from 'react-icons/fa';
import { DriverDetailsResponseModel } from '../../../../Types/Drivers';
import ImageComponent from '../../../../components/imagePreview';

interface DriverInfoPanelProps {
  driverDetails: DriverDetailsResponseModel;
}

const DriverInfoPanel: React.FC<DriverInfoPanelProps> = ({ driverDetails }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  
  // Function to handle image click for enlarged view
  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };
  
  // Function to close the enlarged image view
  const closeImageView = () => {
    setSelectedImage(null);
  };
  
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
      {/* Driver Profile Card */}
      <div className="lg:col-span-1">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex flex-col items-center mb-6">
            {driverDetails.passportFile ? (
              <div 
                className="w-32 h-32 rounded-full mb-4 overflow-hidden cursor-pointer border-4 border-darkBlue-50"
                onClick={() => handleImageClick(driverDetails.passportFile!)}
              >
                <ImageComponent
                  imageUrl={driverDetails.passportFile}
                  altText={`${driverDetails.name}'s profile`}
                //   className="w-full h-full object-cover"
                />
              </div>
            ) : (
              <div className="w-32 h-32 rounded-full mb-4 bg-gray-200 flex items-center justify-center border-4 border-darkBlue-50">
                <FaUserCircle size={80} className="text-gray-400" />
              </div>
            )}
            <h2 className="text-xl font-bold text-gray-800">{driverDetails.name}</h2>
            <p className="text-sm text-gray-500 mt-1">Driver ID: {driverDetails.id!.substring(0, 8)}</p>
            
            <div className="mt-4 flex gap-2">
              <span className={`px-3 py-1 text-xs rounded-full ${
                driverDetails.onboardingStatus === 2 
                  ? 'bg-green-100 text-green-800' 
                  : driverDetails.onboardingStatus === 1
                  ? 'bg-yellow-100 text-yellow-800'
                  : 'bg-gray-100 text-gray-800'
              }`}>
                {driverDetails.onboardingStatus === 2 
                  ? 'Onboarding Completed' 
                  : driverDetails.onboardingStatus === 1
                  ? 'In Review'
                  : 'Pending Onboarding'}
              </span>
            </div>
          </div>
          
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <MdEmail className="text-darkBlue-100 flex-shrink-0" size={18} />
              <div className="flex-1">
                <p className="text-sm text-gray-500">Email</p>
                <p className="font-medium text-gray-700">{driverDetails.emailAddress}</p>
              </div>
            </div>
            
            <div className="flex items-center gap-3">
              <MdPhone className="text-darkBlue-100 flex-shrink-0" size={18} />
              <div className="flex-1">
                <p className="text-sm text-gray-500">Phone</p>
                <p className="font-medium text-gray-700">{driverDetails.phone}</p>
              </div>
            </div>
            
            <div className="flex items-center gap-3">
              <MdLocationOn className="text-darkBlue-100 flex-shrink-0" size={18} />
              <div className="flex-1">
                <p className="text-sm text-gray-500">Country</p>
                <p className="font-medium text-gray-700">{driverDetails.country || 'Not specified'}</p>
              </div>
            </div>
            
            <div className="flex items-center gap-3">
              <MdDateRange className="text-darkBlue-100 flex-shrink-0" size={18} />
              <div className="flex-1">
                <p className="text-sm text-gray-500">Joined</p>
                <p className="font-medium text-gray-700">
                  {
                //   driverDetails.createdAt ? formatDate(new Date(driverDetails.createdAt)) :
                   'Not available'}
                </p>
              </div>
            </div>
          </div>
          
          {driverDetails.hasAcceptedTerms && (
            <div className="mt-6 pt-4 border-t border-gray-100">
              <div className="flex items-center gap-2 text-green-600">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <span className="text-sm font-medium">Terms & Conditions Accepted</span>
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Driver Documents and Truck Info */}
      <div className="lg:col-span-2">
        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
          <h3 className="text-lg font-medium text-gray-800 mb-4">Identity Documents</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Driver's License */}
            <div className="border border-gray-200 rounded-lg p-4">
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center gap-2">
                  <FaIdCard className="text-darkBlue-100" size={18} />
                  <h4 className="font-medium text-gray-700">Driver's License</h4>
                </div>
                <span className="px-2.5 py-0.5 text-xs rounded-full bg-yellow-100 text-yellow-800">
                  Pending Review
                </span>
              </div>
              
              {driverDetails.driversLicence ? (
                <div 
                  className="relative aspect-video rounded-md overflow-hidden cursor-pointer border border-gray-200"
                  onClick={() => handleImageClick(driverDetails.driversLicence!)}
                >
                  <ImageComponent
                    imageUrl={driverDetails.driversLicence}
                    altText="Driver's License"
                    // className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-20 transition-all flex items-center justify-center">
                    <span className="opacity-0 hover:opacity-100 text-white font-medium">View</span>
                  </div>
                </div>
              ) : (
                <div className="aspect-video rounded-md bg-gray-100 flex items-center justify-center">
                  <p className="text-gray-400">No license uploaded</p>
                </div>
              )}
            </div>
            
            {/* ID Card/Passport */}
            <div className="border border-gray-200 rounded-lg p-4">
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center gap-2">
                  <FaIdCard className="text-darkBlue-100" size={18} />
                  <h4 className="font-medium text-gray-700">ID Card/Passport</h4>
                </div>
                <span className="px-2.5 py-0.5 text-xs rounded-full bg-yellow-100 text-yellow-800">
                  Pending Review
                </span>
              </div>
              
              {driverDetails.passportFile ? (
                <div 
                  className="relative aspect-video rounded-md overflow-hidden cursor-pointer border border-gray-200"
                  onClick={() => handleImageClick(driverDetails.passportFile!)}
                >
                  <ImageComponent
                    imageUrl={driverDetails.passportFile}
                    altText="ID Card/Passport"
                    // className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-20 transition-all flex items-center justify-center">
                    <span className="opacity-0 hover:opacity-100 text-white font-medium">View</span>
                  </div>
                </div>
              ) : (
                <div className="aspect-video rounded-md bg-gray-100 flex items-center justify-center">
                  <p className="text-gray-400">No ID uploaded</p>
                </div>
              )}
            </div>
          </div>
        </div>
        
        {/* Bank Details Summary */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-medium text-gray-800 mb-4">Bank Account Information</h3>
          
          {driverDetails.bankAccounts && driverDetails.bankAccounts.length > 0 ? (
            <div className="space-y-4">
              {driverDetails.bankAccounts.map((account, index) => (
                <div key={account.id || index} className="border border-gray-200 rounded-lg p-4">
                  <div className="flex justify-between items-start">
                    <div>
                      <h4 className="font-medium text-gray-700">{account.bankName}</h4>
                      <p className="text-sm text-gray-500 mt-1">
                        {account.accountHolderName} • {account.accountNumber || '****'}
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      {account.isDefault && (
                        <span className="px-2.5 py-0.5 text-xs rounded-full bg-blue-100 text-blue-800">
                          Default
                        </span>
                      )}
                      <span className={`px-2.5 py-0.5 text-xs rounded-full ${
                        account.isVerified 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {account.isVerified ? 'Verified' : 'Unverified'}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500">No bank accounts added yet.</p>
          )}
        </div>
      </div>
      
      {/* Image Modal */}
      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4"
          onClick={closeImageView}
        >
          <div className="relative max-w-4xl max-h-full">
            <img 
              src={selectedImage} 
              alt="Enlarged view" 
              className="max-w-full max-h-[85vh] object-contain rounded-lg"
            />
            <button 
              className="absolute top-4 right-4 bg-white rounded-full p-2 shadow-lg"
              onClick={closeImageView}
            >
              <svg className="w-6 h-6 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DriverInfoPanel;