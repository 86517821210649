import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { Filter } from 'lucide-react';
import CustomAlertDialog from '../../../../components/CustomAlertDialog';
import { TermsAndConditionsService, DocumentTypes } from '../../../../services/TermsAndConditionsService';
import { TermsAndConditions } from '../../../../Types/TermsAndConditions';
import TermsAndConditionsModal from './TermsAndConditionsModal';

// Function to get readable document type names
const getDocumentTypeName = (documentType: any) => {
    switch (documentType) {
        case DocumentTypes.TERMS_OF_SERVICE:
            return 'Terms of Service';
        case DocumentTypes.PRIVACY_POLICY:
            return 'Privacy Policy';
        case DocumentTypes.COMPANY_POLICY:
            return 'Company Policy';
        case DocumentTypes.DRIVER_AGREEMENT:
            return 'Driver Agreement';
        default:
            return documentType;
    }
};

const TermsAndConditionsList = () => {
    const [termsAndConditions, setTermsAndConditions] = useState<TermsAndConditions[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingTerms, setEditingTerms] = useState<TermsAndConditions | undefined>(undefined);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertType, setAlertType] = useState<'warning' | 'error' | 'success'>('warning'); 
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [pendingDeleteId, setPendingDeleteId] = useState(null);
    const [currentTerms, setCurrentTerms] = useState<TermsAndConditions[]>([]);
    const [filterDocumentType, setFilterDocumentType] = useState('');

    useEffect(() => {
        fetchTermsAndConditions();
        fetchCurrentTerms();
    }, [filterDocumentType]);

    const fetchTermsAndConditions = async () => {
        try {
            let response;

            if (filterDocumentType) {
                response = await TermsAndConditionsService.getByDocumentType(filterDocumentType);
            } else {
                response = await TermsAndConditionsService.getAll();
            }

            if (response.isSuccessful && Array.isArray(response.data)) {
                setTermsAndConditions(response.data);
            } else {
                toast.error('Failed to fetch documents');
            }
        } catch (error) {
            toast.error('Error fetching documents');
        }
    };

    const fetchCurrentTerms = async () => {
        try {
            const response = await TermsAndConditionsService.getAllCurrent();
            if (response.isSuccessful && Array.isArray(response.data)) {
                setCurrentTerms(response.data);
            }
        } catch (error) {
            console.error('Error fetching current documents:', error);
        }
    };

    const handleCreate = async (terms: TermsAndConditions) => {
        try {
            const response = await TermsAndConditionsService.create(terms);
            if (response.isSuccessful) {
                fetchTermsAndConditions();
                fetchCurrentTerms();
                setIsModalOpen(false);
                toast.success('Document created successfully!');
            }
        } catch (error) {
            toast.error('Failed to create document');
        }
    };

    const handleUpdate = async (terms: TermsAndConditions) => {
        try {
            const response = await TermsAndConditionsService.update(terms);
            if (response.isSuccessful) {
                fetchTermsAndConditions();
                fetchCurrentTerms();
                setIsModalOpen(false);
                setEditingTerms(undefined);
                toast.success('Document updated successfully!');
            }
        } catch (error) {
            toast.error('Failed to update document');
        }
    };

    const promptDelete = (id: any) => {
        setPendingDeleteId(id);
        setAlertOpen(true);
        setAlertType('warning');
        setAlertTitle('Delete Document');
        setAlertMessage('Are you sure you want to delete this document? This action cannot be undone.');
    };

    const confirmDelete = async () => {
        if (!pendingDeleteId) return;

        try {
            const response = await TermsAndConditionsService.delete(pendingDeleteId);
            if (response.isSuccessful) {
                fetchTermsAndConditions();
                fetchCurrentTerms();
                toast.success('Document deleted successfully');
            }
        } catch (error) {
            toast.error('Failed to delete document');
        } finally {
            setAlertOpen(false);
            setPendingDeleteId(null);
        }
    };

    const handleMakeCurrent = async (id: any) => {
        try {
            const response = await TermsAndConditionsService.makeCurrent(id);
            if (response.isSuccessful) {
                fetchTermsAndConditions();
                fetchCurrentTerms();
                toast.success('Document set as current version');
            }
        } catch (error) {
            toast.error('Failed to update document status');
        }
    };

    return (
        <div className="container mx-auto">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Legal Documents</h1>
                <div className="flex items-center space-x-2">
                    <div className="flex items-center border border-gray-300 rounded px-3 py-2">
                        <Filter size={16} className="mr-2 text-gray-500" />
                        <select
                            value={filterDocumentType}
                            onChange={(e) => setFilterDocumentType(e.target.value)}
                            className="bg-transparent border-none focus:outline-none text-sm"
                        >
                            <option value="">All Document Types</option>
                            <option value={DocumentTypes.TERMS_OF_SERVICE}>Terms of Service</option>
                            <option value={DocumentTypes.PRIVACY_POLICY}>Privacy Policy</option>
                            <option value={DocumentTypes.COMPANY_POLICY}>Company Policy</option>
                            <option value={DocumentTypes.DRIVER_AGREEMENT}>Driver Agreement</option>
                        </select>
                    </div>
                    <button
                        onClick={() => {
                            setEditingTerms(undefined);
                            setIsModalOpen(true);
                        }}
                        className="text-white-100 bg-darkBlue-100 rounded-md flex items-center py-2 px-4 text-sm hover:bg-blue-700"
                    >
                        <FaPlus className="mr-2" />
                        Add Document
                    </button>
                </div>
            </div>

            {currentTerms.length > 0 && (
                <div className="mb-8">
                    <h2 className="text-lg font-semibold mb-4">Current Active Versions</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        {currentTerms.map((terms) => (
                            <div
                                key={terms.id}
                                className="bg-white p-4 border-l-4 border-green-500 rounded shadow-sm hover:shadow-md transition-shadow"
                            >
                                <div className="flex justify-between items-start">
                                    <div>
                                        <span className="text-xs font-medium px-2 py-1 bg-blue-100 text-blue-800 rounded-full">
                                            {getDocumentTypeName(terms.documentType)}
                                        </span>
                                        <h3 className="font-semibold mt-2">{terms.title}</h3>
                                        <p className="text-xs text-gray-500 mt-1">
                                            Effective: {format(new Date(terms.effectiveDate), 'MMM dd, yyyy')}
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-3 flex space-x-2">
                                    <button
                                        onClick={() => {
                                            setEditingTerms(terms);
                                            setIsModalOpen(true);
                                        }}
                                        className="text-xs text-white-100 px-2 py-1 bg-blue-600 text-white rounded hover:bg-blue-700"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => window.open(`/terms-preview/${terms.id}`, '_blank')}
                                        className="text-xs text-white-200 px-2 py-1 bg-green-400 text-white rounded hover:bg-gray-700"
                                    >
                                        Preview
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className="overflow-x-auto bg-white rounded-lg shadow">
                <table className="w-full">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Document Type
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Title
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Effective Date
                            </th>
                            <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Status
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Last Updated
                            </th>
                            <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {termsAndConditions.map((terms) => (
                            <tr
                                key={terms.id}
                                className={terms.isCurrentVersion ? 'bg-green-50' : 'hover:bg-gray-50'}
                            >
                                <td className="px-4 py-3 whitespace-nowrap">
                                    <span className="text-xs font-medium px-2 py-1 bg-gray-100 text-gray-800 rounded-full">
                                        {getDocumentTypeName(terms.documentType)}
                                    </span>
                                </td>
                                <td className="px-4 py-3">{terms.title}</td>
                                <td className="px-4 py-3 whitespace-nowrap">
                                    {format(new Date(terms.effectiveDate), 'MMM dd, yyyy')}
                                </td>
                                <td className="px-4 py-3 text-center">
                                    {terms.isCurrentVersion ? (
                                        <span className="px-2 py-1 text-xs bg-green-100 text-green-800 rounded-full">
                                            Current
                                        </span>
                                    ) : (
                                        <span className="px-2 py-1 text-xs bg-gray-100 text-gray-800 rounded-full">
                                            Archived
                                        </span>
                                    )}
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap">
                                    {terms.updatedAt && format(new Date(terms.updatedAt), 'MMM dd, yyyy')}
                                </td>
                                <td className="px-4 py-3 text-center space-x-2 whitespace-nowrap">
                                    <button
                                        onClick={() => {
                                            setEditingTerms(terms);
                                            setIsModalOpen(true);
                                        }}
                                        className="text-blue-600 hover:text-blue-800 text-sm"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => window.open(`/terms-preview/${terms.id}`, '_blank')}
                                        className="text-gray-600 hover:text-gray-800 text-sm"
                                    >
                                        View
                                    </button>
                                    {!terms.isCurrentVersion && (
                                        <>
                                            <button
                                                onClick={() => handleMakeCurrent(terms.id)}
                                                className="text-green-600 hover:text-green-800 text-sm"
                                            >
                                                Make Current
                                            </button>
                                            <button
                                                onClick={() => promptDelete(terms.id)}
                                                className="text-red-600 hover:text-red-800 text-sm"
                                            >
                                                Delete
                                            </button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                        {termsAndConditions.length === 0 && (
                            <tr>
                                <td colSpan={6} className="px-4 py-8 text-center text-gray-500">
                                    No documents found. {!filterDocumentType ? 'Click "Add Document" to create one.' : 'Try changing the filter.'}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <TermsAndConditionsModal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setEditingTerms(undefined);
                }}
                onSubmit={editingTerms ? handleUpdate : handleCreate}
                initialData={editingTerms}
            />

            <CustomAlertDialog
                isOpen={alertOpen}
                type={alertType}
                title={alertTitle}
                message={alertMessage}
                confirmLabel="Delete"
                cancelLabel="Cancel"
                onConfirm={confirmDelete}
                onCancel={() => {
                    setAlertOpen(false);
                    setPendingDeleteId(null);
                }}
            />
        </div>
    );
};

export default TermsAndConditionsList;