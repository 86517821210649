import React, { useState, useEffect } from 'react';
import { Trash2, Edit, Plus } from 'lucide-react';
import { toast } from 'react-toastify';
import { DocumentTypeService } from '../../../../services/DocumentTypeService';
import { DocumentType } from '../../../../Types';
import DocumentTypeModal from './document-type-modal';
import CustomAlertDialog from '../../../../components/CustomAlertDialog'; // <-- import the custom dialog
import { FaPlus } from 'react-icons/fa';

const DocumentTypeList: React.FC = () => {
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingDocType, setEditingDocType] = useState<DocumentType | undefined>();

    // State for handling custom alert
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertType, setAlertType] = useState<'warning' | 'error' | 'success'>('warning');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [pendingDeleteId, setPendingDeleteId] = useState<string | null>(null);

    useEffect(() => {
        fetchDocumentTypes();
    }, []);

    const fetchDocumentTypes = async () => {
        try {
            const response = await DocumentTypeService.getAll();
            console.log(response);

            if (response.isSuccessful && Array.isArray(response.data)) {
                setDocumentTypes(response.data);
            } else {
                toast.error('Invalid data format');
            }
        } catch (error) {
            toast.error('Failed to fetch document types');
        }
    };

    const handleCreate = async (documentType: DocumentType) => {
        try {
            const response = await DocumentTypeService.create(documentType);
            if (response.isSuccessful) {
                fetchDocumentTypes();
                setIsModalOpen(false);
                toast.success('Document type created successfully!');
            }
        } catch (error) {
            toast.error('Failed to create document type');
        }
    };

    const handleUpdate = async (documentType: DocumentType) => {
        try {
            const response = await DocumentTypeService.update(documentType);
            if (response.isSuccessful) {
                fetchDocumentTypes();
                setIsModalOpen(false);
                setEditingDocType(undefined);
                toast.success('Document type updated successfully!');
            }
        } catch (error) {
            toast.error('Failed to update document type');
        }
    };

    // Show the custom alert dialog instead of window.confirm
    const promptDelete = (id: string) => {
        setPendingDeleteId(id);
        setAlertOpen(true);
        setAlertType('warning');
        setAlertTitle('Delete Document Type');
        setAlertMessage('Are you sure you want to delete this document type?');
    };

    const confirmDelete = async () => {
        if (!pendingDeleteId) return;

        try {
            const response = await DocumentTypeService.delete(pendingDeleteId);
            console.log(response);


            fetchDocumentTypes();
            toast.success('Document type deleted successfully');

        } catch (error) {
            toast.error('Failed to delete document type');
        } finally {
            // Close the dialog and reset the pending ID
            setAlertOpen(false);
            setPendingDeleteId(null);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Document Types</h1>
                <button
                    onClick={() => setIsModalOpen(true)}
                    className="text-white-100 bg-darkBlue-100 rounded-md flex items-center py-3 pr-4 text-xs hover:bg-gray-500"
                >
                    <span>
                        <FaPlus className="w-10" />
                    </span>{" "}
                    <span>      Add Document Type</span>
                </button>
            </div>

            <div className="overflow-x-auto">
                <table className="w-full bg-white shadow-md rounded-lg">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-4 py-3 text-left">Country</th>
                            <th className="px-4 py-3 text-left">Entity Type</th>
                            <th className="px-4 py-3 text-left">Name</th>
                            <th className="px-4 py-3 text-center">Required</th>
                            <th className="px-4 py-3 text-center">Has Template</th>
                            <th className="px-4 py-3 text-right">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentTypes && documentTypes.map((docType) => (
                            <tr key={docType.id} className="border-b hover:bg-gray-50">
                                <td className="px-4 py-3">{docType.country}</td>
                                <td className="px-4 py-3">{docType.entityType}</td>
                                <td className="px-4 py-3">{docType.name}</td>
                                <td className="px-4 py-3 text-center">
                                    {docType.isRequired ? '✓' : '✗'}
                                </td>
                                <td className="px-4 py-3 text-center">
                                    {docType.hasTemplate ? '✓' : '✗'}
                                </td>
                                <td className="px-4 py-3 text-right space-x-2">
                                    <button
                                        onClick={() => {
                                            setEditingDocType(docType);
                                            setIsModalOpen(true);
                                        }}
                                        className="text-blue-600 hover:text-blue-800"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => docType.id && promptDelete(docType.id)}
                                        className="text-red-600 hover:text-red-800"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal for CREATE/UPDATE Document Type */}
            <DocumentTypeModal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setEditingDocType(undefined);
                }}
                onSubmit={editingDocType ? handleUpdate : handleCreate}
                initialData={editingDocType}
            />

            {/* Custom Alert Dialog for DELETE Confirmation */}
            <CustomAlertDialog
                isOpen={alertOpen}
                type={alertType}
                title={alertTitle}
                message={alertMessage}
                confirmLabel="Delete"
                cancelLabel="Cancel"
                onConfirm={confirmDelete}
                onCancel={() => {
                    setAlertOpen(false);
                    setPendingDeleteId(null);
                }}
            />
        </div>
    );
};

export default DocumentTypeList;
