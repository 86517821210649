import React, { useEffect, useState } from 'react';
import { HiOutlineDocumentText, HiOutlineCheckCircle, HiOutlineXCircle } from 'react-icons/hi';
import { MdOutlineFileUpload } from 'react-icons/md';
import { DriverDetailsResponseModel } from '../../../../Types/Drivers';
import api from '../../../../http/api/api_client';
import ImageComponent from '../../../../components/imagePreview';
import TruckImagePreviewModal from './ImagePreviewModal';

// Define interfaces for document types and statuses
interface DocumentType {
  id: string;
  name: string;
  description: string;
  isRequired: boolean;
  hasTemplate: boolean;
  templateUrl?: string;
}

interface DriverDocumentStatus {
  documentTypeId: string;
  documentTypeName: string;
  isRequired: boolean;
  isUploaded: boolean;
  approvalStatus: 'NotUploaded' | 'Pending' | 'Approved' | 'Rejected';
  fileUrl: string | null;
  rejectionReason: string | null;
}

interface DocumentsReviewPanelProps {
  driverId: string;
  driverDetails: DriverDetailsResponseModel;
}

const DocumentsReviewPanel: React.FC<DocumentsReviewPanelProps> = ({ driverId, driverDetails }) => {
  const [documentStatuses, setDocumentStatuses] = useState<DriverDocumentStatus[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState<DriverDocumentStatus | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [rejectionReason, setRejectionReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState('');

  // Fetch document statuses when the component mounts
  useEffect(() => {
    const fetchDocuments = async () => {
      setIsLoading(true);
      try {
        // Call the API endpoint to get document statuses for this driver
        const response: any = await api.get(`/api/DriverDocuments/summary/${driverId}`);
        
        if (response.statusCode === 200 && response.data) {
          setDocumentStatuses(response.data);
        }
      } catch (error) {
        console.error('Error fetching document statuses:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDocuments();
  }, [driverId]);

  // Handle document selection
  const handleDocumentSelect = (document: DriverDocumentStatus) => {
    setSelectedDocument(document);
    setRejectionReason(''); // Clear previous rejection reason
    setSubmitError('');
    setSubmitSuccess('');
  };

  // Handle image view
  const handleImageView = (imageUrl: string | null) => {
    if (imageUrl) {
      setSelectedImage(imageUrl);
    }
  };

  // Close image modal
  const closeImageModal = () => {
    setSelectedImage(null);
  };

  // Handle document approval
  const handleApproveDocument = async () => {
    if (!selectedDocument) return;
    
    setIsSubmitting(true);
    setSubmitError('');
    setSubmitSuccess('');
    
    try {
      // Call API to approve document
      const response: any = await api.put(`/api/DriverDocuments/approve/${selectedDocument.documentTypeId}`);
      
      if (response.statusCode === 200) {
        // Update local state
        setDocumentStatuses(prev => 
          prev.map(doc => 
            doc.documentTypeId === selectedDocument.documentTypeId 
              ? { ...doc, approvalStatus: 'Approved', rejectionReason: null } 
              : doc
          )
        );
        setSubmitSuccess('Document has been approved successfully');
        
        // Update the selected document as well
        setSelectedDocument({
          ...selectedDocument,
          approvalStatus: 'Approved',
          rejectionReason: null
        });
      } else {
        setSubmitError('Failed to approve document. Please try again.');
      }
    } catch (error) {
      console.error('Error approving document:', error);
      setSubmitError('An error occurred while approving the document');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle document rejection
  const handleRejectDocument = async () => {
    if (!selectedDocument) return;
    
    if (!rejectionReason.trim()) {
      setSubmitError('Please provide a reason for rejection');
      return;
    }
    
    setIsSubmitting(true);
    setSubmitError('');
    setSubmitSuccess('');
    
    try {
      // Call API to reject document
      const response: any = await api.put(`/api/DriverDocuments/reject/${selectedDocument.documentTypeId}`, rejectionReason);
      
      if (response.statusCode === 200) {
        // Update local state
        setDocumentStatuses(prev => 
          prev.map(doc => 
            doc.documentTypeId === selectedDocument.documentTypeId 
              ? { ...doc, approvalStatus: 'Rejected', rejectionReason: rejectionReason } 
              : doc
          )
        );
        setSubmitSuccess('Document has been rejected');
        
        // Update the selected document as well
        setSelectedDocument({
          ...selectedDocument,
          approvalStatus: 'Rejected',
          rejectionReason: rejectionReason
        });
      } else {
        setSubmitError('Failed to reject document. Please try again.');
      }
    } catch (error) {
      console.error('Error rejecting document:', error);
      setSubmitError('An error occurred while rejecting the document');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Get status style based on approval status
  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Approved':
        return 'bg-green-100 text-green-800';
      case 'Rejected':
        return 'bg-red-100 text-red-800';
      case 'Pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'NotUploaded':
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Render loading state
  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="animate-pulse">
          <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="space-y-3">
            {[1, 2, 3].map((i) => (
              <div key={i} className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="h-20 bg-gray-200 rounded col-span-1"></div>
                <div className="h-20 bg-gray-200 rounded col-span-2"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
      {/* Document List */}
      <div className="lg:col-span-1">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-medium text-gray-800 mb-4">Required Documents</h3>
          
          {documentStatuses.length === 0 ? (
            <div className="text-center py-8">
              <HiOutlineDocumentText className="w-12 h-12 mx-auto text-gray-400" />
              <p className="mt-2 text-gray-500">No required documents found</p>
            </div>
          ) : (
            <div className="space-y-3">
              {documentStatuses.map((document) => (
                <div
                  key={document.documentTypeId}
                  className={`
                    border rounded-lg p-4 cursor-pointer transition-all
                    ${selectedDocument?.documentTypeId === document.documentTypeId 
                      ? 'border-darkBlue-100 bg-darkBlue-50 bg-opacity-10' 
                      : 'border-gray-200 hover:border-gray-300'
                    }
                  `}
                  onClick={() => handleDocumentSelect(document)}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h4 className="font-medium text-gray-800">{document.documentTypeName}</h4>
                      <p className="text-sm text-gray-500 mt-1">
                        {document.isRequired ? 'Required' : 'Optional'}
                      </p>
                    </div>
                    <span className={`px-2.5 py-1 text-xs rounded-full ${getStatusStyle(document.approvalStatus)}`}>
                      {document.approvalStatus === 'NotUploaded' ? 'Not Uploaded' : document.approvalStatus}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Document Preview and Actions */}
      <div className="lg:col-span-2">
        {selectedDocument ? (
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-start mb-6">
              <h3 className="text-lg font-medium text-gray-800">{selectedDocument.documentTypeName}</h3>
              <span className={`px-3 py-1 text-sm rounded-full ${getStatusStyle(selectedDocument.approvalStatus)}`}>
                {selectedDocument.approvalStatus === 'NotUploaded' ? 'Not Uploaded' : selectedDocument.approvalStatus}
              </span>
            </div>

            {/* Document Preview */}
            {selectedDocument.isUploaded && selectedDocument.fileUrl ? (
              <div className="mb-6">
                <div 
                  className="relative aspect-video rounded-lg overflow-hidden border border-gray-200 cursor-pointer"
                  onClick={() => handleImageView(selectedDocument.fileUrl)}
                >
                  <TruckImagePreviewModal
                    imageUrl={selectedDocument.fileUrl}
                    altText={selectedDocument.documentTypeName}
                    isOpen={selectedImage !== null}
                    onClose={closeImageModal}
                    // className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-20 transition-all flex items-center justify-center">
                    <span className="opacity-0 hover:opacity-100 text-white font-medium">View Full Size</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mb-6 border border-dashed border-gray-300 rounded-lg p-10 text-center">
                <MdOutlineFileUpload className="w-12 h-12 mx-auto text-gray-400" />
                <p className="mt-2 text-gray-500">No document uploaded yet</p>
              </div>
            )}

            {/* Rejection Reason (if rejected) */}
            {selectedDocument.approvalStatus === 'Rejected' && selectedDocument.rejectionReason && (
              <div className="mb-6 p-4 bg-red-50 border border-red-100 rounded-lg">
                <h4 className="font-medium text-red-800 mb-2">Rejection Reason:</h4>
                <p className="text-red-700">{selectedDocument.rejectionReason}</p>
              </div>
            )}

            {/* Action Buttons - Only show if document is uploaded and pending */}
            {selectedDocument.isUploaded && selectedDocument.approvalStatus === 'Pending' && (
              <div className="space-y-4">
                <div className="flex gap-4">
                  <button
                    onClick={handleApproveDocument}
                    disabled={isSubmitting}
                    className="flex-1 flex items-center justify-center gap-2 bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <HiOutlineCheckCircle className="w-5 h-5" />
                    <span>Approve Document</span>
                  </button>
                  
                  <button
                    onClick={handleRejectDocument}
                    disabled={isSubmitting || !rejectionReason.trim()}
                    className="flex-1 flex items-center justify-center gap-2 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <HiOutlineXCircle className="w-5 h-5" />
                    <span>Reject Document</span>
                  </button>
                </div>
                
                <div>
                  <label htmlFor="rejectionReason" className="block text-sm font-medium text-gray-700 mb-1">
                    Reason for rejection (required to reject)
                  </label>
                  <textarea
                    id="rejectionReason"
                    value={rejectionReason}
                    onChange={(e) => setRejectionReason(e.target.value)}
                    rows={3}
                    className="w-full border border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-darkBlue-100 focus:border-transparent"
                    placeholder="Please provide a detailed reason for rejection..."
                  />
                </div>
                
                {/* Error/Success Messages */}
                {submitError && (
                  <div className="p-3 bg-red-50 border border-red-100 rounded-md text-red-700 text-sm">
                    {submitError}
                  </div>
                )}
                
                {submitSuccess && (
                  <div className="p-3 bg-green-50 border border-green-100 rounded-md text-green-700 text-sm">
                    {submitSuccess}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-sm p-6 flex flex-col items-center justify-center text-center h-full">
            <HiOutlineDocumentText className="w-16 h-16 text-gray-400" />
            <h3 className="mt-4 text-lg font-medium text-gray-800">No Document Selected</h3>
            <p className="mt-2 text-gray-500 max-w-md">
              Select a document from the list to view details and take action
            </p>
          </div>
        )}
      </div>

      {/* Fullscreen Image Modal */}
      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4"
          onClick={closeImageModal}
        >
          <div className="relative max-w-4xl max-h-full">
            <img 
              src={selectedImage} 
              alt="Document full view" 
              className="max-w-full max-h-[90vh] object-contain rounded-lg" 
            />
            <button 
              className="absolute top-4 right-4 bg-white rounded-full p-2 shadow-lg"
              onClick={closeImageModal}
            >
              <svg className="w-6 h-6 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentsReviewPanel;