import React, { useEffect, useState } from 'react';
import { FaTruck, FaCalendarAlt, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { MdDirectionsCar, MdSpeed, MdAttachMoney, MdWarning } from 'react-icons/md';
import { DriverDetailsResponseModel } from '../../../../Types/Drivers';
import api from '../../../../http/api/api_client';
import { AllTruckResponseModel, TruckiTypeNames } from '../../../../Types/Trucks';
import ImageComponent from '../../../../components/imagePreview';
import { formatDate } from 'date-fns';
import FormattedDate from '../../../../components/FormattedDate';

interface TruckDetailsPanelProps {
  driverId: string;
  driverDetails: DriverDetailsResponseModel;
}

const TruckDetailsPanel: React.FC<TruckDetailsPanelProps> = ({ driverId, driverDetails }) => {
  const [truckDetails, setTruckDetails] = useState<AllTruckResponseModel | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [rejectionReason, setRejectionReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState('');

  // Fetch truck details
  useEffect(() => {
    const fetchTruckDetails = async () => {
      setIsLoading(true);
      try {
        // First check if truck details are already in driver data
        if (driverDetails.truck) {
          setTruckDetails(driverDetails.truck);
        } else {
          // Otherwise, fetch the truck data associated with this driver
          const response: any = await api.get(`/api/Truck/GetMyTrucks?driverId=${driverId}`);
          if (response.statusCode === 200 && response.data && response.data.length > 0) {
            setTruckDetails(response.data[0]); // Get the first truck (drivers usually have one truck)
          }
        }
      } catch (error) {
        console.error('Error fetching truck details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTruckDetails();
  }, [driverId, driverDetails]);

  // Handle image view in full-screen
  const handleImageView = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  // Close image modal
  const closeImageModal = () => {
    setSelectedImage(null);
  };

  // Approve truck
  const handleApproveTruck = async () => {
    if (!truckDetails) return;
    
    setIsSubmitting(true);
    setSubmitError('');
    setSubmitSuccess('');
    
    try {
      // Call API to approve truck
      const response: any = await api.post(`/api/Truck/UpdateTruckApprovalStatus?truckId=${truckDetails.id}&approvalStatus=Approved`);
      
      if (response.statusCode === 200) {
        setTruckDetails(prev => prev ? { ...prev, approvalStatus: 1 } : null);
        setSubmitSuccess('Truck has been approved successfully');
      } else {
        setSubmitError('Failed to approve truck. Please try again.');
      }
    } catch (error) {
      console.error('Error approving truck:', error);
      setSubmitError('An error occurred while approving the truck');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Decline truck
  const handleDeclineTruck = async () => {
    if (!truckDetails) return;
    
    if (!rejectionReason.trim()) {
      setSubmitError('Please provide a reason for declining');
      return;
    }
    
    setIsSubmitting(true);
    setSubmitError('');
    setSubmitSuccess('');
    
    try {
      // Call API to decline truck
      const response: any = await api.post(`/api/Truck/UpdateTruckApprovalStatus?truckId=${truckDetails.id}&approvalStatus=NotApproved`);
      
      if (response.statusCode === 200) {
        // Store rejection reason in notes or a dedicated field if available
        setTruckDetails(prev => prev ? { ...prev, approvalStatus: 2 } : null);
        setSubmitSuccess('Truck has been declined');
        
        // Send rejection reason if API supports it
        // await api.post(`/api/Truck/SetRejectionReason?truckId=${truckDetails.id}`, rejectionReason);
      } else {
        setSubmitError('Failed to decline truck. Please try again.');
      }
    } catch (error) {
      console.error('Error declining truck:', error);
      setSubmitError('An error occurred while declining the truck');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Helper function to format approval status
  const getApprovalStatusDisplay = (status: number) => {
    switch (status) {
      case 1:
        return { text: 'Approved', class: 'bg-green-100 text-green-800' };
      case 2:
        return { text: 'Declined', class: 'bg-red-100 text-red-800' };
      case 4:
        return { text: 'Blocked', class: 'bg-red-100 text-red-800' };
      default:
        return { text: 'Pending Approval', class: 'bg-yellow-100 text-yellow-800' };
    }
  };

  // Render loading state
  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="animate-pulse">
          <div className="h-6 bg-gray-200 rounded w-1/4 mb-6"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="h-64 bg-gray-200 rounded"></div>
            <div className="space-y-4">
              <div className="h-6 bg-gray-200 rounded w-3/4"></div>
              <div className="h-6 bg-gray-200 rounded w-1/2"></div>
              <div className="h-6 bg-gray-200 rounded w-5/6"></div>
              <div className="h-6 bg-gray-200 rounded w-2/3"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render no truck state
  if (!truckDetails) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-8 text-center">
        <FaTruck className="w-16 h-16 text-gray-400 mx-auto" />
        <h3 className="mt-4 text-lg font-medium text-gray-800">No Truck Registered</h3>
        <p className="mt-2 text-gray-500 max-w-md mx-auto">
          This driver has not registered any trucks yet.
        </p>
      </div>
    );
  }

  // Get formatted status
  const statusDisplay = getApprovalStatusDisplay(truckDetails.approvalStatus ||0);

  return (
    <div className="space-y-6">
      {/* Truck Details Card */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        {/* Header */}
        <div className="p-6 border-b border-gray-100">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium text-gray-800">Truck Details</h3>
            <span className={`px-3 py-1 text-sm rounded-full ${statusDisplay.class}`}>
              {statusDisplay.text}
            </span>
          </div>
        </div>
        
        {/* Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
          {/* Truck Images */}
          <div className="space-y-6">
            {/* External Truck Image */}
            <div>
              <h4 className="text-sm font-medium text-gray-500 mb-2">External Truck Photo</h4>
              {truckDetails.externalTruckPictureUrl ? (
                <div 
                  className="aspect-video rounded-lg overflow-hidden cursor-pointer border border-gray-200"
                  onClick={() => handleImageView(truckDetails.externalTruckPictureUrl || '')}
                >
                  <ImageComponent
                    imageUrl={truckDetails.externalTruckPictureUrl}
                    altText="External truck view"
                    // className="w-full h-full object-cover"
                  />
                </div>
              ) : (
                <div className="aspect-video flex items-center justify-center bg-gray-100 rounded-lg border border-gray-200">
                  <p className="text-gray-400">No external photo available</p>
                </div>
              )}
            </div>
            
            {/* Cargo Space Image */}
            <div>
              <h4 className="text-sm font-medium text-gray-500 mb-2">Cargo Space Photo</h4>
              {truckDetails.cargoSpacePictureUrl ? (
                <div 
                  className="aspect-video rounded-lg overflow-hidden cursor-pointer border border-gray-200"
                  onClick={() => handleImageView(truckDetails.cargoSpacePictureUrl || '')}
                >
                  <ImageComponent
                    imageUrl={truckDetails.cargoSpacePictureUrl}
                    altText="Cargo space view"
                    // className="w-full h-full object-cover"
                  />
                </div>
              ) : (
                <div className="aspect-video flex items-center justify-center bg-gray-100 rounded-lg border border-gray-200">
                  <p className="text-gray-400">No cargo space photo available</p>
                </div>
              )}
            </div>
          </div>
          
          {/* Truck Info */}
          <div className="space-y-6">
            {/* Basic Details */}
            <div className="space-y-4">
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">Truck Name</span>
                <span className="font-medium text-gray-800 mt-1">
                  {truckDetails.truckName || 'Not specified'}
                </span>
              </div>
              
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">License Plate</span>
                <span className="font-medium text-gray-800 mt-1">
                  {truckDetails.plateNumber}
                </span>
              </div>
              
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">Type</span>
                <span className="font-medium text-gray-800 mt-1">
                  {
                //   TruckiTypeNames[truckDetails.truckType] || 
                  'Unknown Type'}
                </span>
              </div>
              
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">Capacity</span>
                <span className="font-medium text-gray-800 mt-1">
                  {truckDetails.truckCapacity}
                </span>
              </div>
              
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">Current Status</span>
                <span className="font-medium text-gray-800 mt-1">
                  {truckDetails.truckStatus}
                </span>
              </div>
            </div>
            
            {/* Document Expiry Dates */}
            <div className="space-y-4">
              <h4 className="font-medium text-gray-700">Document Expiry Dates</h4>
              
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="p-3 bg-gray-50 rounded-md">
                  <div className="flex items-start gap-3">
                    <FaCalendarAlt className="text-darkBlue-100 mt-1" />
                    <div>
                      <p className="text-sm text-gray-500">Truck License</p>
                      <p className="font-medium text-gray-800">
                        {truckDetails.truckLicenseExpiryDate || 'Not available'}
                      </p>
                    </div>
                  </div>
                </div>
                
                <div className="p-3 bg-gray-50 rounded-md">
                  <div className="flex items-start gap-3">
                    <FaCalendarAlt className="text-darkBlue-100 mt-1" />
                    <div>
                      <p className="text-sm text-gray-500">Road Worthiness</p>
                      <p className="font-medium text-gray-800">
                        {truckDetails.roadWorthinessExpiryDate || 'Not available'}
                      </p>
                    </div>
                  </div>
                </div>
                
                <div className="p-3 bg-gray-50 rounded-md">
                  <div className="flex items-start gap-3">
                    <FaCalendarAlt className="text-darkBlue-100 mt-1" />
                    <div>
                      <p className="text-sm text-gray-500">Insurance</p>
                      <p className="font-medium text-gray-800">
                        {truckDetails.insuranceExpiryDate || 'Not available'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Additional Info */}
            <div className="flex items-center gap-2 text-sm text-gray-500">
              <div className="flex-shrink-0">
                <MdWarning className="w-5 h-5 text-gray-400" />
              </div>
              <p>
                Truck added on {FormattedDate(new Date(truckDetails.createdAt || ''))}
                {truckDetails.isDriverOwnedTruck && ' (driver-owned truck)'}
              </p>
            </div>
          </div>
        </div>
        
        {/* Approval Actions - Only show if pending approval */}
        {truckDetails.approvalStatus === 0 && (
          <div className="p-6 bg-gray-50 border-t border-gray-100">
            <div className="space-y-4">
              <div className="flex gap-4">
                <button
                  onClick={handleApproveTruck}
                  disabled={isSubmitting}
                  className="flex-1 flex items-center justify-center gap-2 bg-green-600 hover:bg-green-700 text-white py-3 px-4 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <FaCheckCircle className="w-5 h-5" />
                  <span>Approve Truck</span>
                </button>
                
                <button
                  onClick={handleDeclineTruck}
                  disabled={isSubmitting || !rejectionReason.trim()}
                  className="flex-1 flex items-center justify-center gap-2 bg-red-600 hover:bg-red-700 text-white py-3 px-4 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <FaTimesCircle className="w-5 h-5" />
                  <span>Decline Truck</span>
                </button>
              </div>
              
              <div>
                <label htmlFor="truckRejectionReason" className="block text-sm font-medium text-gray-700 mb-1">
                  Reason for declining (required to decline)
                </label>
                <textarea
                  id="truckRejectionReason"
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                  rows={3}
                  className="w-full border border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-darkBlue-100 focus:border-transparent"
                  placeholder="Please provide a detailed reason for declining this truck..."
                />
              </div>
              
              {/* Error/Success Messages */}
              {submitError && (
                <div className="p-3 bg-red-50 border border-red-100 rounded-md text-red-700 text-sm">
                  {submitError}
                </div>
              )}
              
              {submitSuccess && (
                <div className="p-3 bg-green-50 border border-green-100 rounded-md text-green-700 text-sm">
                  {submitSuccess}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      
      {/* Truck Documents Card */}
      {truckDetails.documents && truckDetails.documents.length > 0 && (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-medium text-gray-800 mb-4">Truck Documents</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {truckDetails.documents.map((document, index) => (
              <div 
                key={index} 
                className="border border-gray-200 rounded-lg overflow-hidden cursor-pointer hover:shadow-md transition-shadow"
                onClick={() => handleImageView(document)}
              >
                <div className="aspect-video">
                  <ImageComponent
                    imageUrl={document}
                    altText={`Truck document ${index + 1}`}
                    // className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-3 border-t border-gray-200 bg-gray-50">
                  <p className="text-sm font-medium text-gray-700">Document {index + 1}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      
      {/* Fullscreen Image Modal */}
      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4"
          onClick={closeImageModal}
        >
          <div className="relative max-w-4xl max-h-full">
            <img 
              src={selectedImage} 
              alt="Truck image full view" 
              className="max-w-full max-h-[90vh] object-contain rounded-lg" 
            />
            <button 
              className="absolute top-4 right-4 bg-white rounded-full p-2 shadow-lg"
              onClick={closeImageModal}
            >
              <svg className="w-6 h-6 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TruckDetailsPanel;