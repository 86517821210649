import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FaCircleArrowLeft, FaTruck, FaIdCard } from "react-icons/fa6";
import { DriverDetailsResponseModel } from "../../../../Types/Drivers";
import api, { ApiResponse } from "../../../../http/api/api_client";
import { GET_ALL_DRIVERS_BYID } from "../../../../helpers/constant";
import TabNavigation from "../components/TabNavigation";
import DetailsShimmerCard from "../../../../components/shimmer/DetailsShimmerCard";
import DriverInfoPanel from "../components/DriverInfoPanel";
import DocumentsReviewPanel from "../components/DocumentsReviewPanel";
import TruckDetailsPanel from "../components/TruckDetailsPanel";

// Define tab types for strong typing
type TabType = 'details' | 'documents' | 'truck';

const DriverDetailsPage: React.FC = () => {
  const { driversId } = useParams(); 
  const [driverDetails, setDriverDetails] = useState<DriverDetailsResponseModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<TabType>('details');

  useEffect(() => {
    const fetchDriverDetails = async () => {
      setIsLoading(true);
      try {
        const response: ApiResponse<any> = await api.get(`${GET_ALL_DRIVERS_BYID}?id=${driversId}`);
        if (response.statusCode === 200) {
          setDriverDetails(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching driver data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDriverDetails();
  }, [driversId]);

  // Handle changing tabs
  const handleTabChange = (tab: TabType) => {
    setActiveTab(tab);
  };

  return (
    <div className="bg-gray-50 min-h-screen p-6">
      {/* Back navigation */}
      <Link
        to="/dashboard/drivers"
        className="flex items-center gap-2 text-darkBlue-100 mb-6 hover:underline transition-all"
      >
        <FaCircleArrowLeft size={18} />
        <span className="font-medium">Back to Drivers</span>
      </Link>

      {/* Page header */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <div className="flex items-center gap-2 text-sm text-gray-500 mb-2">
            <span>Drivers</span>
            <span>/</span>
            <span>Driver Details</span>
          </div>
          <h1 className="text-2xl font-bold text-gray-800">
            {isLoading ? "Loading..." : driverDetails?.name || "Driver Details"}
          </h1>
        </div>

        <div className="flex items-center gap-2">
          {!isLoading && driverDetails?.isActive && (
            <span className="px-3 py-1 bg-green-100 text-green-800 text-sm rounded-full">
              Active
            </span>
          )}
          {!isLoading && !driverDetails?.isActive && (
            <span className="px-3 py-1 bg-red-100 text-red-800 text-sm rounded-full">
              Inactive
            </span>
          )}
        </div>
      </div>

      {/* Tab navigation */}
      <TabNavigation
        activeTab={activeTab}
        onTabChange={handleTabChange}
        tabs={[
          { id: 'details', label: 'Driver Details', icon: <FaIdCard /> },
          { id: 'documents', label: 'Documents', icon: <FaIdCard /> },
          { id: 'truck', label: 'Truck Details', icon: <FaTruck /> }
        ]}
      />

      {/* Content area */}
      <div className="mt-6">
        {isLoading ? (
          <DetailsShimmerCard />
        ) : driverDetails ? (
          <>
            {activeTab === 'details' && (
              <DriverInfoPanel driverDetails={driverDetails} />
            )}

            {activeTab === 'documents' && (
              <DocumentsReviewPanel driverId={driversId || ''} driverDetails={driverDetails} />
            )}

            {activeTab === 'truck' && (
              <TruckDetailsPanel driverId={driversId || ''} driverDetails={driverDetails} />
            )}
          </>
        ) : (
          <div className="bg-white rounded-lg p-8 text-center shadow-sm">
            <h3 className="text-lg font-medium text-red-600 mb-2">Error Loading Driver</h3>
            <p className="text-gray-600">
              We couldn't retrieve this driver's details. Please try again later.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DriverDetailsPage;