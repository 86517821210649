import React, { useState, useEffect } from 'react';
import { X, Check } from 'lucide-react';
import { DocumentType, DocumentTypeModalProps } from '../../../../Types';
import FormFile from '../../../../components/formfile/FormFile';
import { VscCloudUpload } from 'react-icons/vsc';
import Modal from '../../../../components/modal/Modal';

const DocumentTypeModal: React.FC<DocumentTypeModalProps> = ({
    isOpen,
    onClose,
    onSubmit,
    initialData
}) => {
    const [formData, setFormData] = useState<DocumentType>({
        country: '',
        entityType: '',
        name: '',
        isRequired: false,
        description: '',
        hasTemplate: false,
        templateUrl: ''
    });

    useEffect(() => {
        if (initialData) {
            setFormData(initialData);
        }
    }, [initialData]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleToggle = (field: keyof DocumentType) => {
        setFormData(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
        // Reset form data immediately
        setFormData({
            country: '',
            entityType: '',
            name: '',
            isRequired: false,
            description: '',
            hasTemplate: false,
            templateUrl: ''
        });
    };

    if (!isOpen) return null;

    return (
        <Modal isVisible={isOpen} onClosed={() => {
            onClose();
            setFormData({
                country: '',
                entityType: '',
                name: '',
                isRequired: false,
                description: '',
                hasTemplate: false,
                templateUrl: ''
            });
        }}>
            <h1 className="mb-10 font-bold">{initialData ? 'Edit' : 'Add'} Document Type</h1>

            <form onSubmit={handleSubmit} className="space-y-4">
                {/* Country */}
                <div className="flex flex-col gap-2">
                    <label className="block text-sm font-medium text-gray-700">Country</label>
                    <select
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
                        required
                    >
                        <option value="">Select Country</option>
                        <option value="NG">Nigeria</option>
                        <option value="US">United States</option>
                        {/* Add more countries as needed */}
                    </select>
                </div>

                {/* Entity Type */}
                <div className="flex flex-col gap-2">
                    <label className="block text-sm font-medium text-gray-700">Entity Type</label>
                    <select
                        name="entityType"
                        value={formData.entityType}
                        onChange={handleChange}
                        className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
                        required
                    >
                        <option value="">Select Entity Type</option>
                        <option value="Driver">Driver</option>
                        <option value="Business">Business</option>
                        <option value="Other">Other</option>
                        {/* Add more entity types as needed */}
                    </select>
                </div>

                {/* Name */}
                <div className="flex flex-col gap-2">
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
                        required
                    />
                </div>

                {/* Description */}
                <div className="flex flex-col gap-2">
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="py-3 pl-4 w-full border border-gray-400 rounded-md bg-transparent"
                    />
                </div>

                {/* Checkboxes */}
                <div className="flex items-center justify-between">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            checked={formData.isRequired}
                            onChange={() => handleToggle('isRequired')}
                            className="rounded border-gray-300 text-indigo-600 shadow-sm"
                        />
                        <span className="ml-2 text-sm text-gray-700">Is Required</span>
                    </label>

                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            checked={formData.hasTemplate}
                            onChange={() => handleToggle('hasTemplate')}
                            className="rounded border-gray-300 text-indigo-600 shadow-sm"
                        />
                        <span className="ml-2 text-sm text-gray-700">Has Template</span>
                    </label>
                </div>

                {/* Upload Template Section */}
                {formData.hasTemplate && (
                    <div>
                        <FormFile
                            id="template-upload"
                            fileText="Upload Document Template"
                            fileText2="Drag & drop file or"
                            browse="Browse"
                            icon={<VscCloudUpload />}
                            onUpload={(url: string) =>
                                setFormData(prev => ({
                                    ...prev,
                                    templateUrl: url
                                }))
                            }
                        />
                    </div>
                )}

                {/* Buttons */}
                <div className="flex justify-end space-x-2 mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-darkBlue-100 text-white-100 rounded-md hover:bg-darkBlue-100  flex items-center"
                    >
                        <Check size={20} className="mr-2" />
                        Save
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default DocumentTypeModal;
