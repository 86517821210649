
export interface Trucks {
  id: string;
  documents: string[];
  certOfOwnerShip: null;
  plateNumber: string;
  truckCapacity: string;
  driverId: null | string;
  driverName: null | string;
  capacity: string;
  truckOwnerId: string;
  truckOwnerName: string;
  truckName: null | string;
  truckType: number;
  truckLicenseExpiryDate: Date;
  roadWorthinessExpiryDate: Date;
  insuranceExpiryDate: Date;
  truckNumber: null;
  truckStatus: number;
  createdAt: Date;
}


export interface AllTruckResponseModel {
  id?:                       string;
  documents?:                string[];
  plateNumber?:              string;
  truckCapacity?:            string;
  driverId?:                 string;
  truckOwnerId?:             string;
  driverName?:               string;
  truckOwnerName?:           string;
  truckType?:                number;
  truckName?:                string;
  capacity?:                 string;
  truckStatus?:              number;
  truckNumber?:              string;
  truckLicenseExpiryDate?:   string;
  roadWorthinessExpiryDate?: string;
  insuranceExpiryDate?:      string;
  approvalStatus?:           number;
  isDriverOwnedTruck?:       boolean;
  externalTruckPictureUrl?:  string;
  cargoSpacePictureUrl?:     string;
  createdAt?:                Date;
}

export interface AddTruckData {
  documents: string[];
  plateNumber: string;
  truckCapacity: string;
  driverId: string;
  truckName: string;
  truckOwnerId: string;
  truckType: number;
  truckLicenseExpiryDate: string;
  roadWorthinessExpiryDate: string;
  insuranceExpiryDate: string;
}

export enum TruckiType {
  Flatbed,
  BoxBody,
  BucketBody,
  Lowbed,
  ContainerizedBody,
  Refrigerator,
}

export const TruckiTypeNames: Record<number, string> = {
  [TruckiType.Flatbed]: "Flatbed",
  [TruckiType.BoxBody]: "Box Body",
  [TruckiType.BucketBody]: "Bucket Body",
  [TruckiType.Lowbed]: "Lowbed",
  [TruckiType.ContainerizedBody]: "Containerized Body",
  [TruckiType.Refrigerator]: "Refrigerator",
};
// Define reverse mapping
