"use client";
import React, { useEffect, useState } from "react";
import SideBar from "../side-nav/SideBar";
import Header from "../header/Header";
import { TfiMenu } from "react-icons/tfi";
import { Outlet, useNavigate } from "react-router-dom";
import { MainLayoutProps } from "../../Types";
import { ToastContainer } from "react-toastify";
import Preloader from "../Preloader";

const MainLayout: React.FC<MainLayoutProps> = ({ isLoading }) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const router = useNavigate();
  const mediaQuery = window.matchMedia("(min-width: 1024px)");
  const [isLargeScreen, setIsLargeScreen] = useState(mediaQuery.matches);

  const handleShowSideBar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    const handleMediaQueryChange = (e: MediaQueryListEvent) => {
      setIsLargeScreen(e.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    if (isLargeScreen) {
      setShowSidebar(true);
    }

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, [isLargeScreen, mediaQuery]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('tokenKey');
        if (!token) {
          router('/dashboard');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    };
    checkAuth();
  }, [router]);

  return (
    <div className="relative min-h-screen">
      {/* {isLoading && <Preloader />} */}

      <div className="flex">
        {showSidebar && (
          <div className="w-64 fixed left-0 top-0 bottom-0 z-30">
            <SideBar handleShowSideBar={handleShowSideBar} />
          </div>
        )}

        <div className={`flex-1 ${showSidebar ? 'ml-64' : ''}`}>
          <Header handleShowSideBar={handleShowSideBar} showSideBar={showSidebar} />

          {/* Add top padding or margin here to offset the fixed header */}
          <div className="pt-20 p-4">
            {!showSidebar && <button
              onClick={handleShowSideBar}
              className="lg:hidden fixed top-4 left-4 z-40"
            >
              <TfiMenu />
            </button>}


            <Outlet />
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default MainLayout;