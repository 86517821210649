import api, { ApiResponse } from "../http/api/api_client";
import { DocumentType } from "../Types";

export const DocumentTypeService = {
    async getAll() {
      const response = await api.get('/api/documenttypes');
      return response.data;
    },
  
    async create(documentType: DocumentType) {
      const response = await api.post('/api/documenttypes', documentType);
      return response.data;
    },
  
    async update(documentType: DocumentType) {
      const response = await api.put(`/api/documenttypes/${documentType.id}`, documentType);
      return response.data;
    },
  
    async delete(id: string) {
      const response = await api.delete(`/api/documenttypes/${id}`);
      return response.data;
    }
  };