import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { format } from 'date-fns';
import { Printer, ChevronLeft } from 'lucide-react';
import { TermsAndConditionsService, DocumentTypes } from '../../../../services/TermsAndConditionsService';
import { TermsAndConditions } from '../../../../Types/TermsAndConditions';

// Function to get readable document type names
const getDocumentTypeName = (documentType: any) => {
    switch (documentType) {
        case DocumentTypes.TERMS_OF_SERVICE:
            return 'Terms of Service';
        case DocumentTypes.PRIVACY_POLICY:
            return 'Privacy Policy';
        case DocumentTypes.COMPANY_POLICY:
            return 'Company Policy';
        case DocumentTypes.DRIVER_AGREEMENT:
            return 'Driver Agreement';
        default:
            return documentType;
    }
};

const TermsPreview = () => {
    const { id } = useParams();
    const [terms, setTerms] = useState<TermsAndConditions | null>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchTerms(id);
        }
    }, [id]);

    const fetchTerms = async (termsId: string) => {
        setLoading(true);
        try {
            const response = await TermsAndConditionsService.getById(termsId);
            if (response.isSuccessful && response.data) {
                setTerms(response.data);
            }
        } catch (error) {
            console.error('Error fetching document:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePrint = () => {
        window.print();
    };

    const handleBack = () => {
        navigate('/dashboard/settings');
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-darkBlue-100"></div>
            </div>
        );
    }

    if (!terms) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <h2 className="text-2xl font-bold text-gray-700 mb-4">Document Not Found</h2>
                <p className="text-gray-500 mb-8">The requested document could not be found.</p>
                <button
                    onClick={handleBack}
                    className="px-4 py-2 bg-darkBlue-100 text-white rounded flex items-center"
                >
                    <ChevronLeft className="mr-2" size={16} />
                    Back to Settings
                </button>
            </div>
        );
    }

    return (
        <div className="container mx-auto p-6 max-w-4xl">
            <div className="flex justify-between items-center mb-8 print:hidden">
                <button
                    onClick={handleBack}
                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded flex items-center hover:bg-gray-300"
                >
                    <ChevronLeft className="mr-2" size={16} />
                    Back
                </button>
                <button
                    onClick={handlePrint}
                    className="px-4 py-2 text-white-300 bg-darkBlue-100 rounded flex items-center hover:bg-blue-700"
                >
                    <Printer className="mr-2 text-white-300" size={16} />
                    Print
                </button>
            </div>

            <div className="bg-white shadow-md rounded-lg p-8">
                <div className="mb-6 border-b pb-6">
                    <div className="flex justify-center mb-2">
                        <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                            {getDocumentTypeName(terms.documentType)}
                        </span>
                    </div>
                    <h1 className="text-3xl font-bold text-center">{terms.title}</h1>
                    <div className="flex justify-center mt-4 text-gray-600">
                        <p>Effective Date: {format(new Date(terms.effectiveDate), 'MMMM dd, yyyy')}</p>
                        {terms.isCurrentVersion && (
                            <span className="ml-4 px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs">
                                Current Version
                            </span>
                        )}
                    </div>
                </div>

                <div className="prose max-w-none">
                    <ReactMarkdown>{terms.content}</ReactMarkdown>
                </div>

                <div className="mt-8 text-gray-500 text-sm border-t pt-4">
                    <p>Last Updated: {terms.updatedAt && format(new Date(terms.updatedAt), 'MMMM dd, yyyy')}</p>
                    <p>Document ID: {terms.id}</p>
                </div>
            </div>
        </div>
    );
};

export default TermsPreview;