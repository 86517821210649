import React from 'react';

// Define the tab object type
interface Tab {
  id: string;
  label: string;
  icon?: React.ReactNode;
}

interface TabNavigationProps {
  activeTab: string;
  onTabChange: (tabId: any) => void;
  tabs: Tab[];
}

const TabNavigation: React.FC<TabNavigationProps> = ({ 
  activeTab, 
  onTabChange, 
  tabs 
}) => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-1">
      <div className="flex flex-wrap">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => onTabChange(tab.id)}
            className={`
              flex items-center px-6 py-3 text-sm font-medium rounded-md
              transition-all duration-200 ease-in-out
              ${activeTab === tab.id 
                ? 'bg-darkBlue-100 text-white-100 shadow-md' 
                : 'text-gray-600 hover:bg-gray-100'
              }
            `}
          >
            {tab.icon && (
              <span className={`mr-2 ${activeTab === tab.id ? 'text-white' : 'text-gray-500'}`}>
                {tab.icon}
              </span>
            )}
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabNavigation;