import React from 'react';
import { AlertTriangle, XCircle, CheckCircle } from 'lucide-react';

type AlertType = 'warning' | 'error' | 'success';

interface CustomAlertDialogProps {
  isOpen: boolean;
  type?: AlertType;
  title: string;
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const CustomAlertDialog: React.FC<CustomAlertDialogProps> = ({
  isOpen,
  type = 'warning',
  title,
  message,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  onConfirm,
  onCancel
}) => {
  if (!isOpen) return null;

  // Choose an icon based on the type
  let IconComponent = AlertTriangle; // default to warning
  if (type === 'error') IconComponent = XCircle;
  if (type === 'success') IconComponent = CheckCircle;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-[10000]">
      {/* Dialog Container */}
      <div className="bg-white-100 rounded-md p-6 w-full max-w-sm shadow-md">
        <div className="flex flex-col items-center text-center">
          {/* Icon */}
          <IconComponent
            size={48}
            className={
              type === 'warning' ? 'text-yellow-500' :
              type === 'error' ? 'text-red-500' :
              'text-green-500'
            }
          />
          {/* Title */}
          <h2 className="text-xl font-bold mt-2 mb-2">{title}</h2>
          {/* Message */}
          <p className="text-gray-700 mb-4">{message}</p>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-center space-x-4">
          <button
            onClick={onCancel}
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300"
          >
            {cancelLabel}
          </button>
          <button
            onClick={onConfirm}
            className={
              type === 'error'
                ? 'bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700'
                : 'bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700'
            }
          >
            {confirmLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomAlertDialog;
